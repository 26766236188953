module.exports = [
  {
    name: 'Cabaña 1 El Carpintero Picachu',
    style: 'green',
    image: 'cabanasMin',
    description:
      'Cada cabaña cuenta con una 1 cama (2)doble y una 1 sencilla, Energía Solar baño y ducha privada enchapada y zona con Amaca.',
    capacity: '3 Personas',
  },
  {
    name: 'Cabaña 2 La Mapirosa Morpho',
    style: 'white',
    image: 'cabanasMin',
    description:
      'Cada cabaña cuenta con una 1 cama (2)doble y una 1 sencilla, Energía Solar baño y ducha privada enchapada y zona con Amaca.',
    capacity: '3 Personas',
  },
  {
    name: 'Cabaña 3 La Rana',
    style: 'green',
    image: 'cabanasMin',
    description:
      'Cada cabaña cuenta con una 1 cama (2)doble y una 1 sencilla, Energía Solar baño y ducha privada enchapada y zona con Amaca.',
    capacity: '3 Personas',
  },
  {
    name: 'Cabaña Gigante El Campamento.',
    style: 'white',
    image: 'campamentoMin',
    description:
      'Cuenta con habitaciones con baños compartidos ideal para grupos grandes de viajeros o estudiantes. Acomodación múltiple en camarotes sencillos.',
    capacity: '20 Personas',
  },
];
