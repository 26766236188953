import React from 'react';
import wrappers from '../../config/housing';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { socialLinks } from '../../config/config';
import WallpaperT from '../wallpaper';

const useHousingImg = () => {
  const data = useStaticQuery(graphql`
    {
      cabanasMin: allFile(filter: { relativeDirectory: { eq: "housing/cabanas" } }) {
        nodes {
          publicURL
          id
          childImageSharp {
            sizes {
              srcWebp
            }
          }
        }
      }
      campamentoMin: allFile(filter: { relativeDirectory: { eq: "housing/campamento" } }) {
        nodes {
          publicURL
          id
          childImageSharp {
            sizes {
              srcWebp
            }
          }
        }
      }
    }
  `);
  return data;
};

const Wallpaper = () => {
  const data = useHousingImg();

  return (
    <>
      {wrappers.map((row, i) => (
        <>
          <section id={row.name} className={`wrapper trails ${row.style}`}>
            <header>
              <h2>{row.name}</h2>
            </header>
            <div className="container">
              <div className="content wow animate__an imated animate__bounce animate__zoomIn">
                <div className="container_image">
                  <swiper-container
                    navigation="true"
                    pagination="true"
                    keyboard="true"
                    mousewheel="true"
                    css-mode="true"
                  >
                    {data[row.image].nodes.map(
                      (
                        {
                          childImageSharp: {
                            sizes: { srcWebp: publicURL },
                          },
                        },
                        i,
                      ) => (
                        <swiper-slide>
                          <img src={publicURL || null} alt={`icon-${row.name}`} />
                        </swiper-slide>
                      ),
                    )}
                  </swiper-container>
                </div>
                <div className="container_text">
                  <p>{row.description}</p>
                  <p>
                    <span>Capacidad: </span>
                    {row.capacity}
                  </p>
                  <Link to={socialLinks.whatsapp} className={`button-wrapper primary ${row.float}`}>
                    RESERVAR
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <WallpaperT number={i} />
        </>
      ))}
    </>
  );
};

export default Wallpaper;
