import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { wrappersTransparents } from '../config/home';

const Wallpaper = ({ number }) => {
  const { id, title, p } = wrappersTransparents.items[number];
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query QueryTransparentImg {
      allFile(filter: { relativeDirectory: { eq: "wallpaper" } }) {
        nodes {
          publicURL
          id
        }
      }
    }
  `);
  return (
    <section
      id={id}
      className="wrapper transparent"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(49, 74, 52, 0.46), rgba(49, 74, 52, 0.46)), url(${nodes[number].publicURL})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="content wow animate__animated animate__bounce animate__zoomIn">
            <header>
              <p>{title}</p>
              <h2>{p}</h2>
            </header>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wallpaper;
