import React from 'react';
import { banner } from '../../config/config';
import { graphql, useStaticQuery } from 'gatsby';
import Logo from '../logo';

const Banner = () => {
  const { housing } = banner;
  const {
    file: { publicURL: bannerImg },
  } = useStaticQuery(graphql`
    query QueryBannerImgHosusing {
      file(relativePath: { eq: "body/banner-three-min.jpg" }) {
        publicURL
      }
    }
  `);
  return (
    <section
      id="banner"
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(49, 74, 52, 0.60), rgba(49, 74, 52, 0.60)), url(${bannerImg})`,
      }}
    >
      <div className="banner-content animate__animated animate__bounce animate__slideInDown">
        <Logo />
        <h2 className="animate__animated animate__bounce animate__slideInDown">{housing.title}</h2>
        <p className="animate__animated animate__bounce animate__slideInUp">
          {housing.description}
        </p>
      </div>
    </section>
  );
};

export default Banner;
